/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive, hasPermission, appConfigs } from "../../../../_helpers";
// import { Badge } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    // console.log("URL: " + url + ", isActive: " + checkIsActive(location, url));
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  const { user, settings } = useSelector(
    (state) => ({
      user: state.auth.user,
      settings: state.systemSettings.settings,
    }),
    shallowEqual
  );

  const [role, setRole] = useState(undefined);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (user?.profiles?.active.type === 'agent') {
      setPermissions(settings?.agentPermissions ? JSON.parse(settings?.agentPermissions) : []);
    } else {
      setPermissions(user?.profiles?.active?.role?.permissions || []);
    }
    setRole(
      (user?.profiles?.active.is_owner === 1 
          ? 'admin'
          : (
              user?.profiles?.active.type === 'agent'
                ? 'agent'
                : 'user')));
    // console.log(user?.profiles?.active.role);
  }, [user, settings]);

  return (
      <>
          {/* begin::Menu Nav */}
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
              <li
                  className={`menu-item mb-5 ${getMenuItemActive(
                      '/onboard',
                      false,
                  )}`}
                  aria-haspopup="true"
              >
                  <NavLink className="menu-link onboard" to="/onboard">
                      <span className="svg-icon menu-icon svg-icon-primary">
                          <SVG
                              src={toAbsoluteUrl(
                                  '/media/svg/icons/Navigation/Plus.svg',
                              )}
                          />
                      </span>
                      <span className="menu-text font-weight-bolder text-primary">
                          Onboard Group
                      </span>
                  </NavLink>
              </li>
              <li
                  className={`menu-item mt-1 ${getMenuItemActive(
                      '/dashboard',
                      false,
                  )}`}
                  aria-haspopup="true"
              >
                  <NavLink className="menu-link" to="/dashboard">
                      <span className="svg-icon menu-icon">
                          <SVG
                              src={toAbsoluteUrl(
                                  '/media/svg/icons/Layout/Layout-arrange.svg',
                              )}
                          />
                      </span>
                      <span className="menu-text">Dashboard</span>
                  </NavLink>
              </li>
              {hasPermission(permissions, role, 'accessToGroupsData') && (
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          '/groups',
                          true,
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/groups">
                          <span className="svg-icon menu-icon">
                              <SVG
                                  src={toAbsoluteUrl(
                                      '/media/svg/icons/Communication/Group.svg',
                                  )}
                              />
                          </span>
                          <span className="menu-text">Groups</span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <li
                                  className="menu-item menu-item-parent"
                                  aria-haspopup="true"
                              >
                                  <span className="menu-link">
                                      <span className="menu-text"> </span>
                                  </span>
                              </li>
                              <li
                                  className={`menu-item ${getMenuItemActive(
                                      '/groups/all',
                                  )}`}
                                  aria-haspopup="true"
                              >
                                  <NavLink
                                      className="menu-link"
                                      to="/groups/all"
                                  >
                                      <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                      </i>
                                      <span className="menu-text">
                                          Active Groups
                                      </span>
                                  </NavLink>
                              </li>
                              {/*begin::2 Level*/}
                              <li
                                  className={`menu-item ${getMenuItemActive(
                                      '/groups/suspendedgroups',
                                  )}`}
                                  aria-haspopup="true"
                              >
                                  <NavLink
                                      className="menu-link"
                                      to="/groups/suspendedgroups"
                                  >
                                      <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                      </i>
                                      <span className="menu-text">
                                          Suspended Groups{' '}
                                      </span>
                                  </NavLink>
                              </li>

                              {/*end::2 Level*/}
                              {/*begin::2 Level*/}

                              {/*end::2 Level*/}
                          </ul>
                      </div>
                  </li>
              )}

              {hasPermission(permissions, role, 'accessToMembersData') && (
                  <li
                      className={`menu-item mt-1 ${getMenuItemActive(
                          '/members',
                          false,
                      )}`}
                      aria-haspopup="true"
                  >
                      <NavLink className="menu-link" to="/members">
                          <span className="svg-icon menu-icon">
                              <SVG
                                  src={toAbsoluteUrl(
                                      '/media/svg/icons/Communication/Address-card.svg',
                                  )}
                              />
                          </span>
                          <span className="menu-text">Cohort Members</span>
                      </NavLink>
                  </li>
              )}

              {/*begin::1 Level*/}
              {(hasPermission(permissions, role, 'accessToworkflowData') ||
                  hasPermission(permissions, role, 'accessToLevelOneData') ||
                  hasPermission(permissions, role, 'accessToLevelTwoData') ||
                  hasPermission(permissions, role, 'accessToLevelThreeData') ||
                  hasPermission(permissions, role, 'accessToLevelFourData')) &&
                  appConfigs.slug === 'ngo' && (
                      <li
                          className={`menu-item mt-1 menu-item-submenu ${getMenuItemActive(
                              '/loans',
                              true,
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                      >
                          <NavLink
                              className="menu-link menu-toggle"
                              to="/loans"
                          >
                              <span className="svg-icon menu-icon">
                                  <span className="svg-icon menu-icon">
                                      <SVG
                                          src={toAbsoluteUrl(
                                              '/media/svg/icons/Shopping/Bag2.svg',
                                          )}
                                      />
                                  </span>
                                  {/* <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/wallet2.svg")} /></span> */}
                                  {/* <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/wallet3.svg")} /></span> */}
                              </span>
                              <span className="menu-text">Loans</span>
                              <i className="menu-arrow" />
                          </NavLink>
                          <div className="menu-submenu">
                              <i className="menu-arrow" />
                              <ul className="menu-subnav">
                                  <li
                                      className="menu-item menu-item-parent"
                                      aria-haspopup="true"
                                  >
                                      <span className="menu-link">
                                          <span className="menu-text">
                                              Loans
                                          </span>
                                      </span>
                                  </li>
                                  {/*begin::2 Level*/}
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/loans/loan-applications',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/loans/loan-applications"
                                      >
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Loan Applications
                                          </span>
                                      </NavLink>
                                  </li>
                                  {/*end::2 Level*/}
                                  {/*begin::2 Level*/}
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/loans/loan-products',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/loans/loan-products"
                                      >
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Loan Products
                                          </span>
                                      </NavLink>
                                  </li>
                                  {/*end::2 Level*/}
                                  {/*end::3 Level*/}
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/loans/lendings',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/loans/lendings"
                                      >
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Lendings
                                          </span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                                  {/* Start::4 Level */}
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/loans/cohort-loans',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/loans/cohort-loans"
                                      >
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Cohort Loans
                                          </span>
                                      </NavLink>
                                  </li>

                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/loans/group-loans',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      {/* <NavLink
                                          className="menu-link"
                                          to="/loans/group-loans"
                                      >
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Group Loans
                                          </span>
                                      </NavLink> */}
                                  </li>
                              </ul>
                          </div>
                      </li>
                  )}
              {/*end::1 Level*/}

              {appConfigs.slug === 'bank' &&
                  hasPermission(permissions, role, 'accessToBranchesData') && (
                      <li
                          className={`menu-item mt-1 ${getMenuItemActive(
                              '/branches',
                              false,
                          )}`}
                          aria-haspopup="true"
                      >
                          <NavLink className="menu-link" to="/branches">
                              <span className="svg-icon menu-icon">
                                  <SVG
                                      src={toAbsoluteUrl(
                                          '/media/svg/icons/Design/Difference.svg',
                                      )}
                                  />
                              </span>
                              <span className="menu-text">Branches</span>
                          </NavLink>
                      </li>
                  )}
              {hasPermission(permissions, role, 'accessToAgentsData') && (
                  <li
                      className={`menu-item mt-1 ${getMenuItemActive(
                          '/agents',
                          false,
                      )}`}
                      aria-haspopup="true"
                  >
                      <NavLink className="menu-link" to="/agents">
                          <span className="svg-icon menu-icon">
                              <SVG
                                  src={toAbsoluteUrl(
                                      '/media/svg/icons/General/User.svg',
                                  )}
                              />
                          </span>
                          <span className="menu-text">Agents</span>
                      </NavLink>
                  </li>
              )}
              {/* {appConfigs.slug === 'bank' && <li className={`menu-item mt-1 ${getMenuItemActive("/reports", false)} opacity-30`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/reports">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} /></span>
            <span className="menu-text">Reports</span>
            <small style={{ paddingTop: '6px' }} className="d-none d-md-inline-block">
              <Badge pill variant="secondary" className="px-3">Coming Soon</Badge>
            </small>
          </NavLink>
        </li>} */}
              {(hasPermission(permissions, role, 'accessToGroupsReports') ||
                  hasPermission(permissions, role, 'accessToAgentsReports') ||
                  hasPermission(permissions, role, 'accessToMembersReports') ||
                  hasPermission(permissions, role, 'accessToMeetingsReports') ||
                  hasPermission(permissions, role, 'accessToSavingsReports') ||
                  hasPermission(permissions, role, 'accessToLoansReports')) &&
                  appConfigs.slug === 'ngo' && (
                      <li
                          className={`menu-item mt-1 ${getMenuItemActive(
                              '/reports',
                              false,
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                      >
                          <NavLink
                              className="menu-link menu-toggle"
                              to="/reports"
                          >
                              <span className="svg-icon menu-icon">
                                  <SVG
                                      src={toAbsoluteUrl(
                                          '/media/svg/icons/Files/Selected-file.svg',
                                      )}
                                  />
                              </span>
                              <span className="menu-text">Reports</span>
                          </NavLink>
                          <div className="menu-submenu">
                              <i className="menu-arrow" />
                              <ul className="menu-subnav">
                                  <li
                                      className="menu-item menu-item-parent"
                                      aria-haspopup="true"
                                  >
                                      <span className="menu-link">
                                          <span className="menu-text">
                                              Reports
                                          </span>
                                      </span>
                                  </li>
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToGroupsReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-groups',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/reports/-groups"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Groups
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToMembersReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-members',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/reports/-members"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Members
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-branches")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-branches">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">Branches</span>
                </NavLink>
              </li>} */}
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToAgentsReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-agents',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/reports/-agents"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Agents
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToMeetingsReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-meetings',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/reports/-meetings"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Meetings
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-kpi")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-kpi">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">KPI</span>
                </NavLink>
              </li>} */}
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToSavingsReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-savings',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/reports/-savings"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Savings
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {hasPermission(
                                      permissions,
                                      role,
                                      'accessToLoansReports',
                                  ) && (
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              '/reports/-loans',
                                          )}`}
                                          aria-haspopup="true"
                                      >
                                          <NavLink
                                              className="menu-link"
                                              to="/loans/loans-summary"
                                          >
                                              <i className="menu-bullet menu-bullet-line">
                                                  <span />
                                              </i>
                                              <span className="menu-text">
                                                  Loans
                                              </span>
                                          </NavLink>
                                      </li>
                                  )}
                                  {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-location")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-location">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">Location</span>
                </NavLink>
              </li>} */}
                              </ul>
                          </div>
                      </li>
                  )}
              {(hasPermission(
                  permissions,
                  role,
                  'accessToInstitutionSettings',
              ) ||
                  hasPermission(
                      permissions,
                      role,
                      'accessToInstitutionProfileEdit',
                  ) ||
                  hasPermission(
                      permissions,
                      role,
                      'accessToInstitutionBrandingEdit',
                  ) ||
                  hasPermission(permissions, role, 'accessToUsersRead') ||
                  hasPermission(
                      permissions,
                      role,
                      'accessToGroupsSettingsEdit',
                  )) && (
                  <li
                      className={`menu-item menu-item-submenu mt-1 ${getMenuItemActive(
                          '/settings',
                          true,
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/settings">
                          <span className="svg-icon menu-icon">
                              <SVG
                                  src={toAbsoluteUrl(
                                      '/media/svg/icons/General/Settings-2.svg',
                                  )}
                              />
                          </span>
                          <span className="menu-text">Settings</span>
                      </NavLink>
                      <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <li
                                  className="menu-item menu-item-parent"
                                  aria-haspopup="true"
                              >
                                  <span className="menu-link">
                                      <span className="menu-text">
                                          Settings
                                      </span>
                                  </span>
                              </li>
                              {hasPermission(
                                  permissions,
                                  role,
                                  'accessToInstitutionProfileEdit',
                              ) && (
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/settings/profile',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/settings/profile"
                                      >
                                          <i className="menu-bullet menu-bullet-line">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Profile
                                          </span>
                                      </NavLink>
                                  </li>
                              )}
                              {hasPermission(
                                  permissions,
                                  role,
                                  'accessToInstitutionBrandingEdit',
                              ) && (
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/settings/theming',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/settings/theming"
                                      >
                                          <i className="menu-bullet menu-bullet-line">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Logo &amp; Theme
                                          </span>
                                      </NavLink>
                                  </li>
                              )}
                              {hasPermission(
                                  permissions,
                                  role,
                                  'accessToGroupsSettingsEdit',
                              ) && (
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/settings/services',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/settings/services"
                                      >
                                          <i className="menu-bullet menu-bullet-line">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Groups
                                          </span>
                                      </NavLink>
                                  </li>
                              )}
                              {hasPermission(
                                  permissions,
                                  role,
                                  'accessToUsersRead',
                              ) && (
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/settings/users-roles',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/settings/users-roles"
                                      >
                                          <i className="menu-bullet menu-bullet-line">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              Users &amp; Roles
                                          </span>
                                      </NavLink>
                                  </li>
                              )}
                              {hasPermission(
                                  permissions,
                                  role,
                                  'accessToInstitutionSettings',
                              ) && (
                                  <li
                                      className={`menu-item ${getMenuItemActive(
                                          '/settings/system',
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink
                                          className="menu-link"
                                          to="/settings/system"
                                      >
                                          <i className="menu-bullet menu-bullet-line">
                                              <span />
                                          </i>
                                          <span className="menu-text">
                                              System
                                          </span>
                                      </NavLink>
                                  </li>
                              )}
                          </ul>
                      </div>
                  </li>
              )}
              <li
                  className={`menu-item ${getMenuItemActive('/share', false)}`}
                  aria-haspopup="true"
              >
                  <NavLink className="menu-link" to="/share">
                      <span className="svg-icon menu-icon">
                          <SVG
                              src={toAbsoluteUrl(
                                  '/media/svg/icons/Communication/Share.svg',
                              )}
                          />
                      </span>
                      <span className="menu-text">Share</span>
                  </NavLink>
              </li>
          </ul>
          {/* end::Menu Nav */}
      </>
  );
}
